import { AxiosError } from "axios"
import { NotificationApiInjection } from "naive-ui/es/notification/src/NotificationProvider"
import { AuthError } from "@supabase/gotrue-js"
import { H } from "highlight.run"

export function logAsNotification(e: any, notifications: NotificationApiInjection) {
    if (e instanceof Error) {
        H.consumeError(e)
    }
    if (e instanceof AxiosError) {
        const error = e as AxiosError
        if (error.code === "ERR_CANCELED") {
            return
        }
        if (error.response && error.response?.data) {
            // @ts-ignore
            const data: {
                code: number
                message: string
                title: string
            } = error.response.data

            notifications.error({
                title: data.title,
                content: data.message,
                meta: data.code.toString(),
                keepAliveOnHover: true,
                closable: true,
            })
        } else {
            notifications.error({
                title: "Network Error",
                content: error.message,
                keepAliveOnHover: true,
                closable: true,
            })
        }
    } else if (e instanceof AuthError) {
        notifications.error({
            title: "Auth Error",
            content: e.message,
            keepAliveOnHover: true,
            closable: true,
        })
    } else {
        notifications.error({
            title: e.name,
            content: e.message,
            keepAliveOnHover: true,
            closable: true,
        })
    }
}
